import {Button, Col, DatePicker, Form, Input, message, Popconfirm, Row, Select, Space, Table} from "antd";
import dayjs from "dayjs";
import React, {useCallback, useEffect, useState} from "react";
import {orderService} from "../../service";
import {deviceService} from "../../service/device";
import {DownloadOutlined} from "@ant-design/icons";
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(minMax);
const {RangePicker} = DatePicker;

const downloadFile = (fileName: string): void => {
    fetch(process.env.REACT_APP_BASEURL + "export/" + fileName)
        .then(response => response.blob())
        .then((blob: Blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName;  // 设置下载的文件名
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
        .catch(() => alert('下载文件时发生错误！'));
};

export const OrderList = () => {
    const [form] = Form.useForm();
    const [msgApi, contextHolder] = message.useMessage();


    // 批次列表
    const [batchList, setBatchList] = useState<any[]>([]);
    // 选中的批次
    const [selectedBatchId, setSelectedBatchId] = useState<number>();
    // 订单状态
    const [orderStatus, setOrderStatus] = useState();
    // 设备id
    const [deviceId, setDeviceId] = useState<string>();
    // 订单号
    const [orderNo, setOrderNo] = useState<string>();
    // 第三方订单号
    const [thirdPartyOrderNo, setThirdPartyOrderNo] = useState<string>();
    // 分页参数
    const [pageNum, setPageNum] = useState(1);
    // 分页参数
    const [pageSize, setPageSize] = useState(10);
    // 总数
    const [total, setTotal] = useState(0);
    // 订单列表
    const [orderList, setOrderList] = useState<any[]>([]);
    // 导出loading
    const [exportLoading, setExportLoading] = useState(false)
    // 开始时间
    const [beginTime, setBeginTime] = useState<number>();
    // 结束时间
    const [endTime, setEndTime] = useState<number>();

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        deviceService.getEnableDeviceBatchList()
            .then(res => {
                // console.log("res", res);
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    setBatchList(responseMsg.data);
                } else {
                    message.error(responseMsg.msg).then();
                }
            });
        _search();
    }

    const _search = useCallback(() => {
        // console.log("查询订单列表")
        orderService.queryOrderList({
            pageNum: pageNum - 1,
            pageSize: pageSize,
            status: orderStatus,
            orderNo: orderNo,
            thirdPartyOrderNo: thirdPartyOrderNo,
            batchId: selectedBatchId,
            deviceId: deviceId,
            beginTime: beginTime,
            endTime: endTime
        }).then(res => {
            // console.log(" queryOrderList res", res);
            const responseMsg = res.data;
            if (responseMsg.status === 0) {
                // console.log("查询订单列表成功", responseMsg);
                setOrderList(responseMsg.data.content);
                setTotal(responseMsg.data.totalElements);
            } else {
                // console.log("查询订单列表失败", responseMsg);
                msgApi.error(responseMsg.message).then();
            }
        })
    }, [deviceId, orderNo, orderStatus, pageNum, pageSize, selectedBatchId, thirdPartyOrderNo, beginTime, endTime])

    useEffect(() => {
        // console.log("初始。。。")
        _search();
    }, [_search])

    const _onStatusChange = (value: any) => {
        setOrderStatus(value);
    }

    // const _onBatchChange = (value: any) => {
    //     setSelectedBatchId(value);
    // }

    const exportOrders = useCallback(() => {
        setExportLoading(true);
        orderService.exportOrderList({
            pageNum: 0,
            pageSize: 100000,
            status: orderStatus,
            orderNo: orderNo,
            thirdPartyOrderNo: thirdPartyOrderNo,
            batchId: selectedBatchId,
            deviceId: deviceId,
            beginTime: beginTime,
            endTime: endTime
        }).then(res => {
            console.log("download res", res.data)
            setExportLoading(false);
            downloadFile(res.data.data);
        })
            .catch(err => {
                console.error("download err", err);
                msgApi.error("没有可以下载的数据").then();
                setExportLoading(false);
            })
    }, [deviceId, orderNo, orderStatus, selectedBatchId, thirdPartyOrderNo, beginTime, endTime])

    const columns = [
        {
            key: "batchId",
            dataIndex: "batchId",
            title: "批次号",
        },
        {
            key: "batchName",
            dataIndex: "batchName",
            title: "批次名称",
        },
        {
            key: "orderNo",
            dataIndex: "orderNo",
            title: "订单号",
        },
        {
            key: "thirdPartyOrderNo",
            dataIndex: "thirdPartyOrderNo",
            title: "第三方订单号",
        },
        {
            key: "payPrice",
            dataIndex: "payPrice",
            title: "订单金额",
        },
        {
            key: "deviceId",
            dataIndex: "deviceId",
            title: "设备号",
        },
        {
            key: "deviceTypeTitle",
            dataIndex: "deviceTypeTitle",
            title: "设备类型",
        },
        {
            key: "status",
            dataIndex: "status",
            title: "订单状态",
            render: (text: any, record: any) => {
                if (text === "paid") {
                    return <span style={{color: "green"}}>已支付</span>
                } else if (text === "unpaid") {
                    return <span style={{color: "gray"}}>未支付</span>
                } else {
                    return <span style={{color: "red"}}>未知</span>
                }
            }
        },
        {
            key: "createTime",
            dataIndex: "createTime",
            title: "创建时间",
            render: (text: any) => {
                if (text) {
                    return dayjs.unix(text / 1000).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: "paymentTime",
            dataIndex: "paymentTime",
            title: "支付时间",
            render: (text: any) => {
                if (text) {
                    return dayjs.unix(text / 1000).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        }

    ];
    return <>
        {contextHolder}
        <Space style={{flex: 1, height: '100%', width: '100%'}} direction={'vertical'} size={'large'}>
            <Form form={form}>
                <Row>
                    <Col span={8}>
                        <Form.Item name={"orderStatus"}
                                   label={"订单状态"}
                                   labelCol={{span: 8}}
                                   wrapperCol={{span: 16}}
                            // initialValue={packageGroupList?.[0]?.id}
                        >
                            <Select placeholder={"请选择订单状态"} onChange={_onStatusChange} allowClear={true}>
                                <Select.Option value={"paid"}>已支付</Select.Option>
                                <Select.Option value={"unpaid"}>未支付</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name={"orderNo"}
                                   label={"订单号"}
                                   labelCol={{span: 8}}
                                   wrapperCol={{span: 16}}>
                            <Input placeholder={"请输入订单号"}
                                   allowClear={true}
                                // value={orderNo}
                                   onChange={(e) => {
                                       // console.log("orderNo onChange", e.target.value);
                                       if (e.target.value === "") {
                                           setOrderNo(undefined);
                                       } else {
                                           setOrderNo(e.target.value);
                                       }
                                   }}
                            />
                        </Form.Item>
                    </Col>


                    <Col span={8}>
                        <Form.Item name={"thirdPartyOrderNo"}
                                   label={"第三方订单号"}
                                   labelCol={{span: 8}}
                                   wrapperCol={{span: 16}}>
                            <Input placeholder={"请输入第三方（微信）订单号"} allowClear={true}
                                   onChange={(e) => {
                                       if (e.target.value === "") {
                                           setThirdPartyOrderNo(undefined);
                                       } else {
                                           setThirdPartyOrderNo(e.target.value);
                                       }
                                   }}/>
                        </Form.Item>
                    </Col>

                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={"导入批次"} labelCol={{span: 8}}
                                   wrapperCol={{span: 16}}

                                   name={"batchId"}>
                            <Select
                                options={batchList.map(item => {
                                    return {label: item.batchName, value: item.id}
                                }).sort((a, b) => a.label.localeCompare(b.label))}
                                onChange={(value) => setSelectedBatchId(value)}
                                allowClear={true}
                                placeholder='请选择导入批次'
                                maxTagCount={1}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name={"deviceId"}
                                   label={"设备号"}
                                   labelCol={{span: 8}}
                                   wrapperCol={{span: 16}}>
                            <Input placeholder={"请输入设备号"}
                                   allowClear={true}
                                   onChange={(e) => {
                                       if (e.target.value === "") {
                                           setDeviceId(undefined);
                                       } else {
                                           setDeviceId(e.target.value);
                                       }
                                   }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"_date"}
                                   label={"请选择时间"}
                                   labelCol={{span: 8}}
                                   wrapperCol={{span: 16}}>
                            <RangePicker style={{width: '100%'}} onChange={(dates) => {
                                // console.log("dates", dates)
                                if (dates && dates.length === 2 && dates[0] != null && dates[1] != null) {
                                    // 开始时间从当天0点0分0秒开始
                                    // const fromatBeginTime = dates[0] &&
                                    console.log('start',dates[0].startOf('date').unix() * 1000)
                                    console.log('end',dates[1].endOf('date').unix() * 1000 + 999)
                                    setBeginTime(dates[0].startOf('date').unix() * 1000);
                                    setEndTime(dates[1].endOf('date').unix() * 1000 + 999);
                                } else {
                                    setBeginTime(undefined);
                                    setEndTime(undefined);
                                }
                            }}/>
                        </Form.Item>
                    </Col>

                </Row>
                <Row>
                    <Col span={6} offset={18}>
                        <Space size={'large'}>
                            <Button type={'primary'} onClick={_search} loading={false}>查询</Button>
                            {/*<Button type={'default'} onClick={resetValues}>重置</Button>*/}
                            <Popconfirm
                                title={"该操作会导出当前查询条件下的所有订单，是否继续？"}
                                okText={"确定"}
                                cancelText={"取消"}
                                onConfirm={exportOrders}
                            >
                                <Button loading={exportLoading}><DownloadOutlined/> 导出订单</Button>
                            </Popconfirm>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table columns={columns}
                   rowKey={"orderNo"}
                   dataSource={orderList}
                   pagination={{
                       position: ["bottomRight"],
                       pageSize: pageSize,
                       current: pageNum,
                       total: total,
                       onChange: (page, pageSize) => {
                           setPageNum(page);
                           setPageSize(pageSize || 10);
                       }
                   }}
            />
        </Space>
    </>
}
